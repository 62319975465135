import {FingerprintOutlined} from '@mui/icons-material';
import {Button, IconButton} from '@mui/material';
import Grid from '@mui/material/Grid2';
import {useRouter} from 'next/router';
import {useEffect, useState} from 'react';
import {FormattedMessage} from 'react-intl';
import {Lang} from '../../../Lang';
import {FaceIdIcon} from '../../Common/components/icon/FaceIdIcon';
import {LandingLayout} from '../../Common/components/layout';
import {BiometryTypes} from '../../Common/model/MobilePropsModel';
import {sendDataToRN} from '../../Common/services/CommonService';
import {isNotNullOrUndefinedOrEmpty} from '../../Common/services/FileService';
import {getFromLS, useMatches} from '../../Common/services/PageService';
import {RegistartionInfoDialog} from '../../Registration/components/RegistartionInfoDialog';
import {CreateResetHeslaPozadavekContainer} from '../containers/CreateResetHeslaPozadavekContainer';
import {CookiesButton} from './CookiesButton';
import {LoginPanelHeader} from './LoginPanelHeader';
type Props = {
    onCloseError: () => void;
    openRegistrationDialog: (zadatel?: boolean | undefined) => void;
    disableActions?: boolean;
    environment: {
        clientId?: string;
        redirectUri?: string;
        idmFrontendUri?: string;
    };
};

export const Login = ({openRegistrationDialog, disableActions, onCloseError, environment}: Props) => {
    const matches = useMatches();
    const {query, push} = useRouter();
    const available = getFromLS('mobile')?.biometryAvailable;
    const biometryType = getFromLS('mobile')?.biometryType;
    const canUseBiometrics = getFromLS('mobile').userId;
    const mobileLogin = getFromLS('mobile').isMobile;
    const showBiometricsButton = available && canUseBiometrics;

    const [showCreateResetPozadavek, setShowCreateResetPozadavek] = useState(false);
    const [rememberLogin, setRememberLogin] = useState(true);
    const [infoDialog, setInfoDialog] = useState(false);

    const [showCreateResetPozadavekSubmitted, setShowCreateResetPozadavekSubmitted] = useState(false);

    useEffect(() => {
        if (query?.reset === 'true') {
            setRememberLogin(false);
        }
    }, [query]);
    useEffect(() => {
        if (query?.zadatel === 'true') {
            openRegistrationDialog(true);
        }
    }, [openRegistrationDialog, query]);

    const handleShowCreateResetPozadavek = () => {
        if (!disableActions) {
            setShowCreateResetPozadavek(true);
        }
    };

    const handleHideCreateResetPozadavek = () => {
        // reset state for a hide change password request form
        setShowCreateResetPozadavek(false);
        // make sure that there is no message about submitted request as well
        setShowCreateResetPozadavekSubmitted(false);
    };

    const handleShowResetSubmitted = () => {
        setShowCreateResetPozadavekSubmitted(true);
    };

    const handleLogin = () => {
        if (isNotNullOrUndefinedOrEmpty(environment.idmFrontendUri)) {
            const uri = `${environment.idmFrontendUri}/?redirect_uri=${environment.redirectUri}&client_id=${environment.clientId}&scope=selfcare&response_type=code&mobileLogin=${mobileLogin}`;
            push(uri);
        }
    };

    const handleRememberPassword = () => setRememberLogin(true);
    const handleNewRegistration = () => setRememberLogin(false);
    const handleOpenRegistration = () => openRegistrationDialog(false);
    const handleOpenBiometrics = () => {
        onCloseError();
        sendDataToRN({openBiometrics: true});
    };
    const handleOpenInfoDialog = () => setInfoDialog((prev) => !prev);

    return (
        <LandingLayout login>
            {showCreateResetPozadavek ? (
                <CreateResetHeslaPozadavekContainer
                    showResetSubmitted={showCreateResetPozadavekSubmitted}
                    onClickBack={handleHideCreateResetPozadavek}
                    onSubmitDone={handleShowResetSubmitted}
                />
            ) : (
                <Grid container sx={{width: {sm: 420}}}>
                    <Grid size={{xs: 12}}>
                        <LoginPanelHeader rememberLogin={rememberLogin} rememberPassword={handleRememberPassword} newRegistration={handleNewRegistration} />
                        <Grid
                            container
                            sx={{
                                padding: `20px`,
                                alignItems: 'center',
                            }}
                        >
                            {rememberLogin ? (
                                <Grid container sx={{textAlign: 'center'}}>
                                    <Grid size={{xs: 12}} sx={{display: 'flex', justifyContent: 'center'}}>
                                        <Button
                                            onClick={handleLogin}
                                            variant="contained"
                                            style={{width: 200, marginLeft: showBiometricsButton ? 45 : 0}}
                                            size="large"
                                        >
                                            <FormattedMessage id={Lang.BUTTON_PRIHLASIT_SE} />
                                        </Button>
                                        {showBiometricsButton && (
                                            <IconButton onClick={handleOpenBiometrics}>
                                                {biometryType === BiometryTypes.FaceID ? (
                                                    <FaceIdIcon color="primary" fontSize="large" />
                                                ) : (
                                                    <FingerprintOutlined color="primary" fontSize="large" />
                                                )}
                                            </IconButton>
                                        )}
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <Button
                                            onClick={handleShowCreateResetPozadavek}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            size="large"
                                        >
                                            <FormattedMessage id={Lang.BUTTON_OBNOVA_HESLA} />
                                        </Button>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <Button
                                            onClick={handleOpenInfoDialog}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            size="large"
                                        >
                                            <FormattedMessage id="registrace.jsem.registrovany" />
                                        </Button>
                                    </Grid>
                                    {(mobileLogin || matches) && (
                                        <Grid size={{xs: 12}}>
                                            <CookiesButton isMobile={true} />
                                        </Grid>
                                    )}
                                </Grid>
                            ) : (
                                <Grid container sx={{textAlign: 'center'}}>
                                    <Grid size={{xs: 12}}>
                                        <Button onClick={handleOpenRegistration} sx={{width: 200}} variant="contained" size="large">
                                            <FormattedMessage id={Lang.BUTTON_REGISTRACE} />
                                        </Button>
                                    </Grid>
                                    <Grid size={{xs: 12}}>
                                        <Button
                                            onClick={handleShowCreateResetPozadavek}
                                            sx={{
                                                '&:hover': {
                                                    backgroundColor: 'transparent',
                                                },
                                            }}
                                            size="large"
                                        >
                                            <FormattedMessage id={Lang.BUTTON_OBNOVA_HESLA} />
                                        </Button>
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            )}
            {infoDialog && <RegistartionInfoDialog open={infoDialog} onClose={handleOpenInfoDialog} />}
        </LandingLayout>
    );
};
